export interface HierarchicalMenuItem {
    id: string;
    treeParent?: {
        id: string
    };
    title: string;
    hideForLocale?: boolean
    position: number
}

export interface ParsedMenuItem {
    title: string;
    childMenu: ParsedMenuItem[];
    id: string;
    treeParent?: {
        id: string
    };
    position: number
}

const getChildren = (data, item) => {
    const foundChildren = data.filter(x => x?.treeParent?.id === item?.id);

    if (foundChildren) {
        return {
            ...item,
            childMenu: foundChildren.map(x => getChildren(data, x)).sort((a, b) => a.position - b.position),
        };
    }

    return item;
};

const hierarchicalMenu = (
    data: HierarchicalMenuItem[] = [],
): ParsedMenuItem => {
    const tree = data.map(item => getChildren(data, item)).filter(x => !x.treeParent?.id).sort((a, b) => a.position - b.position);

    return {
        title: '',
        id: 'ROOT',
        childMenu: tree.sort((a, b) => a.position - b.position),
        position: 0,
    };
};

export default hierarchicalMenu;

const findNested = (item: ParsedMenuItem, id: string): ParsedMenuItem|undefined => {
    if (item?.id === id) return item;
    let found;
    item.childMenu.forEach((inner) => {
        const res = findNested(inner, id);
        if (res) {
            found = res;
        }
    });
    return found;
};

export const hierarchicalMenuSelectChild = (data: HierarchicalMenuItem[], startFrom: string): ParsedMenuItem | undefined => {
    const tree = hierarchicalMenu(data);
    return findNested(tree as ParsedMenuItem, startFrom);
};
