import React from "react";
import getVariantsForDisplay, {
    ProductRenderDisplayVariant
} from "../importer/customizer/utils/get-variants-for-display";
import {useQuickQuoteContext} from "./quick-quote-context";
import useImageKeySet from "../hooks/use-image-key-set";
import useLoadableHot from "../importer/customizer/use-loadable-hot";
import {graphql, useStaticQuery} from "gatsby";

interface AllProductsContext {
    filteredVariants: Record<string, ProductRenderDisplayVariant>
    unfilteredVariants: Record<string, ProductRenderDisplayVariant>
}

const Ctx = React.createContext(null as unknown as AllProductsContext)

const AllProductsContext = ({ children }: React.PropsWithChildren) => {

    const { width, height } = useQuickQuoteContext()


    const {
        lightweightVariants
    } = useStaticQuery(graphql`
        {
            lightweightVariants
        }
    `)

    const {
        products,
        fieldDefinitions,
        parsedPriceDefinitions,
        isLoaded,
        load,
    } = useLoadableHot()

    const images = useImageKeySet();

    React.useEffect(() => {
        if (!isLoaded && width && height) {
            load()
        }
    }, [width, height, isLoaded])

    const value = React.useMemo(() => {

        if (!isLoaded) {
            const items = Object.fromEntries(lightweightVariants.map(x => [x.handle, x]))
            return {
                unfilteredVariants: items,
                filteredVariants: items,
            }
        }

        const filteredVariants = products.map(product => getVariantsForDisplay({
            fieldDefinitions,
            product,
            images,
            isDemoMode: true,
            optionalValues: (width && height) ? {
                Width: width,
                Height: height,
                Drop: height,
                '2Lwidth': width,
                '2Rwidth': width,
            } : undefined,
            parsedPriceDefinitions
        })).flat() as ProductRenderDisplayVariant[]

        const unfilteredVariants = products.map(product => getVariantsForDisplay({
            fieldDefinitions,
            product,
            images,
            isDemoMode: true,
            parsedPriceDefinitions
        })).flat() as ProductRenderDisplayVariant[]

        return {
            filteredVariants: Object.fromEntries(filteredVariants.map(x => [x.handle, x])),
            unfilteredVariants: Object.fromEntries(unfilteredVariants.map(x => [x.handle, x])),
        }
    }, [width, height, isLoaded])


    return (
        <Ctx.Provider value={value}>
            {children}
        </Ctx.Provider>
    )
}

export default AllProductsContext

export const useAllProductsContext = () => React.useContext<AllProductsContext>(Ctx)
