import React from 'react';
import { useGridContext } from '../grid/context';
import {css} from "@emotion/react";
import {breakpoints} from "../tokens";

interface AspectRatio extends HtmlPropsNoRef<HTMLDivElement> {
    breakpoints: {
        [key: string]: { width: number, height: number } | 'none'
    }
}

const styles = {
    a1: css({
        position: 'relative',
        '&:before': {
            display: 'block',
            width: '100%',
            content: '""',
            paddingBottom: 'var(--ar-pb-dt)',
            [breakpoints.tb]: {
                paddingBottom: 'var(--ar-pb-tb)',
            },
            [breakpoints.mb]: {
                paddingBottom: 'var(--ar-pb-mb)',
            }
        }
    }),
    a2: css({
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        position: 'var(--ar-rp-dt)',
        [breakpoints.tb]: {
            position: 'var(--ar-rp-tb)',
        },
        [breakpoints.mb]: {
            position: 'var(--ar-rp-mb)',
        }
    })
}

const getRatio = (ratio) => {
    if(!ratio) return
    if (ratio === 'none') {
        return '0'
    }
    const { width, height } = ratio;
    return `calc(${height} / ${width} * 100%)`
}

const getPosition = (ratio)  => {
    if (!ratio) return
    if (ratio === 'none') {
        return 'relative'
    }
    return 'absolute'
}

const AspectRatio = React.memo(({ className, breakpoints, ...props }: AspectRatio): JSX.Element => {

    const dtRatio = getRatio(breakpoints?.dt)
    const tbRatio = getRatio(breakpoints?.tb) || dtRatio
    const mbRatio = getRatio(breakpoints?.mb) || tbRatio

    const dtPos = getPosition(breakpoints?.dt)
    const tbPos = getPosition(breakpoints?.tb) || dtPos
    const mbPos = getPosition(breakpoints?.mb) || tbPos

    return (
        <div
            className={className}
            style={{
                '--ar-pb-dt': dtRatio,
                '--ar-pb-tb': tbRatio,
                '--ar-pb-mb': mbRatio
            }}
            css={styles.a1}
        >
            <div
                css={styles.a2}
                style={{
                    '--ar-rp-dt': dtPos,
                    '--ar-rp-tb': tbPos,
                    '--ar-rp-mb': mbPos
                }}
                {...props}
            />
        </div>
    );
});

export default AspectRatio;
