import React from 'react';
import { PolymorphicWithoutRef } from '../polymorphic';
import { colors } from '../colors';
import { useGridContext } from '../grid/context';
import {breakpoints} from "../tokens";
import {css} from "@emotion/react";


type GradientProps = {
    backgroundColor?: keyof typeof colors | 'none'
};

const styles = css({
    backgroundColor: 'var(--bg-c)',
    transform: 'translateZ(0px)',
    borderRadius: '12px',
    overflow: 'hidden',
    [breakpoints.tb]: {
        borderRadius: '8px'
    }
})
const Background = <C extends React.ElementType = 'div'>({
    as,
    className,
    backgroundColor,
    ...props
}: PolymorphicWithoutRef<C, GradientProps>): JSX.Element => {
    const Component = as || 'div';
    return (
        <Component
            className={className}
            css={styles}
            style={{
                '--bg-c': (() => {
                    if (backgroundColor === 'none') {
                        return 'transparent';
                    }
                    if (backgroundColor && colors[backgroundColor]) {
                        return colors[backgroundColor];
                    }
                    return undefined;
                })()
            }}
            {...props}
        />
    );
};
export default Background;
