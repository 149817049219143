import {graphql, useStaticQuery} from "gatsby";


const useImageKeySet = () => {

    const {
        imageKeySet: {
            data
        }
    } = useStaticQuery(graphql`
        query ImageKeySetQuery {
            imageKeySet {
                data
            }
        }
    `)

    return data
}

export default useImageKeySet
