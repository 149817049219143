import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {useCart, CartWithActions, CartProvider, ShopifyProvider, Cart} from '@shopify/hydrogen-react';
import type {CartLineInput, OrderLineItem} from "@shopify/hydrogen-react/dist/types/storefront-api-types";
import {useAllProductsContext} from "./all-products-context";

interface IStoreContext {
    cart: CartWithActions,
    giftCardError: boolean,
    giftCardValid: boolean,
    loading: boolean
    freeSamplesAllowedCount: number
    addGiftCards: (giftCardValue: string) => void,
    deleteGiftCards: () => void,
    createNewCart: () => void
}

export const Ctx = React.createContext<IStoreContext>({} as any)


const cartFragment = `
          fragment CartFragment on Cart {
            id
            checkoutUrl
            totalQuantity
            buyerIdentity {
              countryCode
              customer {
                id
                email
                firstName
                lastName
                displayName
              }
              email
              phone
            }
            lines(first: $numCartLines) {
              edges {
                node {
                  id
                  quantity
                  attributes {
                    key
                    value
                  }
                  cost {
                    totalAmount {
                      amount
                      currencyCode
                    }
                    compareAtAmountPerQuantity {
                      amount
                      currencyCode
                    }
                  }
                  merchandise {
                    ... on ProductVariant {
                      id
                      availableForSale
                      compareAtPrice {
                        ...MoneyFragment
                      }
                      apiMarkup: metafield(namespace: "$app:customizer", key: "api_markup") {
                        key
                        value
                      }
                      rawSelections: metafield(namespace: "$app:customizer", key: "raw_selections") {
                        key
                        value
                      }
                      richSelections: metafield(namespace: "$app:customizer", key: "rich_selections") {
                        key
                        value
                      }
                      productTitles: metafield(namespace: "$app:customizer", key: "product_titles") {
                        key
                        value
                      }
                      roomName: metafield(namespace: "$app:customizer", key: "room_name") {
                        key
                        value
                      }
                      price {
                        ...MoneyFragment
                      }
                      requiresShipping
                      title
                      image {
                        ...ImageFragment
                      }
                      product {
                        handle
                        title
                        id
                      }
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
              }
            }
            cost {
              subtotalAmount {
                ...MoneyFragment
              }
              totalAmount {
                ...MoneyFragment
              }
              totalDutyAmount {
                ...MoneyFragment
              }
              totalTaxAmount {
                ...MoneyFragment
              }
            }
            note
            attributes {
              key
              value
            }
            discountCodes {
              code
              applicable
            }
          }
        
          fragment MoneyFragment on MoneyV2 {
            currencyCode
            amount
          }
          fragment ImageFragment on Image {
            id
            url
            altText
            width
            height
          }

    `

const StoreContext = ({ children }: React.PropsWithChildren) => {
    return (
        <ShopifyProvider
            storeDomain="shadey-blinds.myshopify.com"
            storefrontApiVersion="2024-07"
            storefrontToken="666898ba27a4875e91520f1989301469"
            languageIsoCode="EN"
            countryIsoCode="AU"
        >
            <CartProvider countryCode="AU" cartFragment={cartFragment}>
                <StoreCP>
                    {children}
                </StoreCP>
            </CartProvider>
        </ShopifyProvider>
    )
}


const StoreCP = ({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element => {

    const {
        datoCmsSiteOptionPage: {
            allowedNumberOfFreeProducts
        }
    } = useStaticQuery(graphql`
        {
            datoCmsSiteOptionPage {
                allowedNumberOfFreeProducts
            }
        }
    `)



    const [giftCardError, setGiftCardError] = React.useState(false);
    const [loading, setLoading] = React.useState(false)

    const cart = useCart();

    const giftCardValid = !!cart?.discountCodes?.find(x => x.applicable)
    const { status, cartCreate, lines, attributes, cost, buyerIdentity, discountCodesUpdate } = cart;


    const freeProductsCurrentlyInCart = (() => {
        if (lines) {
            return lines.filter(x => Number(x?.cost?.amountPerQuantity?.amount || 0) === 0).length
        }
        return 0
    })()

    const allowedFreeSamples = allowedNumberOfFreeProducts - freeProductsCurrentlyInCart

    /* Create a new cart */
    const createNewCart = React.useCallback((): void => {


        cartCreate({})
    }, [cartCreate])

    const variants = useAllProductsContext()

    React.useEffect(() => {
        if (status === "uninitialized" && cart.cartReady) {

            cartCreate({})
        }
    }, [cart.status, cart.cartReady])

    const addGiftCards = (giftCardValue: string) => {
        discountCodesUpdate([giftCardValue])
    }

    React.useEffect(() => {
        if (cart?.discountCodes && cart.discountCodes?.length > 0) {
            const invalidCodes = cart.discountCodes.filter(x => x.applicable === false)
            if (invalidCodes.length > 0) {
                setGiftCardError(true)
                setTimeout(() => {
                    setGiftCardError(false)
                }, 2000)
            }
        }
    }, [cart?.discountCodes])


    React.useEffect(() => {
        if (cart.lines) {
            cart.lines.forEach((line) => {
                if (line && line.id) {
                    const itemHandle = line.merchandise?.product?.handle || ""
                    const linkedItem = variants.unfilteredVariants[itemHandle.replace("-free-sample", "")]
                    if (!linkedItem) {
                        // remove from cart silently
                        cart.linesRemove([line.id])
                    }
                }
            })
        }
    }, [cart?.lines])

    const deleteGiftCards = React.useCallback(() => {
        let lineItems: CartLineInput[] = []
        // create new array for cart lineItemInput
        if (lines && lines.length > 0) {
            lines.forEach((line) => {
                if (line && line.merchandise?.id) {
                    lineItems.push({
                        merchandiseId: line.merchandise.id,
                        quantity: line.quantity
                    })
                }
            })
        }

        const discountCodes: string[] = [];

        (cart.discountCodes || [])
            .forEach((x) => {
                if (x?.code) {
                    discountCodes.push(x.code)
                }
            })


        cartCreate({
            lines: lineItems,
            discountCodes: discountCodes
        })
    }, [cart.discountCodes])

    return (

            <Ctx.Provider value={{
                cart,
                giftCardError,
                loading,
                freeSamplesAllowedCount: allowedFreeSamples,
                createNewCart,
                addGiftCards,
                deleteGiftCards,
                giftCardValid,
            }}>
                {children}

            </Ctx.Provider>
    )
}

export default StoreContext;

export const useStore = (): IStoreContext => React.useContext(Ctx)

