import {
    onRouteUpdate as onRouteUpdateInternal,
    shouldUpdateScroll as shouldUpdateScrollInternal,
    wrapRootElement as wrapRootElementInternal,
    onClientEntry as onClientEntryInternal,
} from './src/gatsby/browser'

export const onRouteUpdate = onRouteUpdateInternal
export const shouldUpdateScroll = shouldUpdateScrollInternal
export const wrapRootElement = wrapRootElementInternal
export const onClientEntry = onClientEntryInternal
