import React from 'react';
import { PolymorphicWithoutRef } from '../polymorphic';
import {css} from "@emotion/react";

export const colors = {
    primaryOrange1: 'var(--color-primary-orange-1)',
    primaryOrange2: 'var(--color-primary-orange-2)',
    primaryOrange3: 'var(--color-primary-orange-3)',
    primaryOrangeHover: 'var(--color-primary-orange-hover)',
    primaryOrange: 'var(--color-primary-orange)',
    secondaryBeige1: 'var(--color-secondary-beige-1)',
    secondaryBeige2: 'var(--color-secondary-beige-2)',
    secondaryBeige: 'var(--color-secondary-beige)',
    secondaryBeige3: 'var(--color-secondary-beige-3)',
    secondaryBeige4: 'var(--color-secondary-beige-4)',
    secondaryBeige5: 'var(--color-secondary-beige-5)',
    tertiaryBlue1: 'var(--color-tertiary-blue-1)',
    tertiaryBlue2: 'var(--color-tertiary-blue-2)',
    tertiaryBlue3: 'var(--color-tertiary-blue-3)',
    tertiaryBlue4: 'var(--color-tertiary-blue-4)',
    tertiaryBlue5: 'var(--color-tertiary-blue-5)',
    tertiaryBlue6: 'var(--color-tertiary-blue-6)',
    neutral1: 'var(--color-neutral-1)',
    neutral2: 'var(--color-neutral-2)',
    neutral3: 'var(--color-neutral-3)',
    neutral4: 'var(--color-neutral-4)',
    neutral5: 'var(--color-neutral-5)',
    neutral6: 'var(--color-neutral-6)',
    neutral7: 'var(--color-neutral-7)',
    neutral8: 'var(--color-neutral-8)',
    neutral9: 'var(--color-neutral-9)',
    neutral10: 'var(--color-neutral-10)',
    shadesWhite: 'var(--color-shades-white)',
    shadesBlack: 'var(--color-shades-black)',
    statesError: 'var(--color-states-error)',
    statesSuccess: 'var(--color-states-success)',
}

export type ColorProps = {
    color?: keyof typeof colors,
    backgroundColor?: keyof typeof colors,
};

const styles = css({
    color: 'var(--col-x)',
    backgroundColor: 'var(--bg-c-x)',
})

const Color = <C extends React.ElementType = 'div'>({
    color,
    backgroundColor,
    as,
    ...props
}: PolymorphicWithoutRef<C, ColorProps>): JSX.Element => {
    const Component = as || 'div';
    return (
        <Component
            {...props}
            css={styles}
            style={{
                '--bg-c-x': (() => {
                    if (backgroundColor && colors[backgroundColor]) {
                        return colors[backgroundColor];
                    }
                    return undefined;
                })(),
                '--col-x': (() => {
                    if (color && colors[color]) {
                        return colors[color];
                    }
                    return undefined;
                })()
            }}
        />
    );
};
export default Color;
