import React from "react";
import {ProductPartial} from "../importer/customizer/utils/get-variants-for-display";

interface ProductCategoryContextProps {
    product: ProductPartial
}

const ProductCategoryBaseContext = React.createContext<ProductPartial>(null as unknown as ProductPartial)

const ProductCategoryContext = ({ children, product }: React.PropsWithChildren<ProductCategoryContextProps>): JSX.Element => {
    return (
        <ProductCategoryBaseContext.Provider value={product}>
            {children}
        </ProductCategoryBaseContext.Provider>
    )
}

export default ProductCategoryContext;

export const useProductCategory = () => React.useContext<ProductPartial>(ProductCategoryBaseContext)
