import React from 'react';
import { GatsbyLinkProps } from 'gatsby';
import {Link} from "../../../../plugins/gatsby-plugin-atollon";
import {colors} from "../colors";

export const StyledLinkStyles = {
    position: 'relative',
    whiteSpace: 'nowrap',
    transition: '0.3s',
    '[role=group]:hover &, [data-state="open"] &': {
        color: colors.primaryOrange,
        '&::before': {
            transform: 'scale(1)',
            right: '0',
            transformOrigin: 'bottom left',
        },
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        left: '0',
        height: '0.5px',
        bottom: '-1px',
        width: '100%',
        backgroundColor: 'currentColor',
        transition: '0.3s transform ease-out',
        transform: 'scale(0)',
        transformOrigin: 'bottom right',
    },
    '&:hover::before': {
        transform: 'scale(1)',
        right: '0',
        transformOrigin: 'bottom left',
    },
    '&:hover': {
        color: colors.primaryOrange
    },

}

const StyledLink = (props: GatsbyLinkProps<any>): JSX.Element => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Link
        css={StyledLinkStyles}
        {...props}
    />
);

export default StyledLink;
