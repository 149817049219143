import React from "react";
import {ProductRenderDisplayVariant} from "../importer/customizer/utils/get-variants-for-display";

interface CompareContext {
    items: ProductRenderDisplayVariant[]
    setItems: React.Dispatch<React.SetStateAction<ProductRenderDisplayVariant[]>>
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Ctx = React.createContext<CompareContext>(null as unknown as CompareContext)

const STORE_KEY = 'compare_ctx_v1'

const CompareContext = ({ children }: React.PropsWithChildren): JSX.Element => {
    const [items, setItems] = React.useState<ProductRenderDisplayVariant[]>([])

    React.useEffect(() => {
        try {
            const existing = localStorage.getItem(STORE_KEY)
            if (existing) {
                const e = JSON.parse(existing)
                if (Array.isArray(e)) {
                    setItems(e)
                }
            }
        } catch (e) {

        }
    }, [])

    React.useEffect(() => {
        try {
            localStorage.setItem(STORE_KEY, JSON.stringify(items))
        } catch (e) {

        }
    }, [items])

    const [isOpen, setIsOpen] = React.useState<boolean>(false)
    const value = React.useMemo(() => ({
        items,
        setItems,
        isOpen,
        setIsOpen,
    }), [items, setItems, isOpen, setIsOpen])
    return (
        <Ctx.Provider value={value}>
            {children}
        </Ctx.Provider>
    )
}

export const useCompareContext = (): CompareContext => React.useContext(Ctx)

export default CompareContext
