import React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import Spinner from '../spinner';

/**
 * Re-usable screen-reader friendly Button component.
 * @alias ButtonProps
 * */
export interface ButtonProps extends HtmlProps<HTMLButtonElement> {
    /**
   * Shows a spinner and disables the button, for when there is a loading action happening.
   *  * Default is false.
   *  * */
    loading?: boolean;
    /**
   * * The type of the button.
   *  * */
    type?: 'submit' | 'button' | 'reset' ;
    className?: string;
    children?: React.ReactNode | string;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export const buttonStyles = {
    cursor: 'pointer',
    appearance: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    outlineOffset: '2px',
    width: 'auto',
    transition: 'opacity 0.3s',
    lineHeight: '1.2',
    '&[disabled]': {
        cursor: 'not-allowed',
    },
} as Interpolation<Theme>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({
        disabled,
        style,
        loading,
        className,
        children,
        type = 'button',
        ...props
    }, ref) => (
        <button
            /* eslint-disable-next-line react/button-has-type */
            type={type}
            ref={ref}
            aria-disabled={disabled}
            disabled={disabled || loading}
            style={style}
            /**
             ** Can buttons that have a loading state be considered a toggle button?
             ** Not sure...
             * */
            className={className}
            aria-pressed={loading !== undefined ? loading : undefined}
            css={buttonStyles}
            {...props}
        >
            <div
                aria-hidden={!loading}
                css={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: loading ? 1 : 0,
                    transition: '0.2s',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                }}
            >
                <Spinner size="sm" />
            </div>
            <span
                aria-hidden={!!loading}
                css={{
                    opacity: loading ? 0 : 1,
                    zIndex: 1,
                    position: 'relative',
                }}
            >
                {children}
            </span>
        </button>
    ),
);

Button.displayName = 'Button';

export default Button;
