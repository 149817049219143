import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";


const MockedAsset = ({ asset, width = 200, ...rest }: { asset: any, width?: number }) => {

    const sizes = [0.25, 0.5, 1].map((dpr) => {
        if (asset?.format === 'svg') {
            return {
                src: asset?.url,
                width: width * dpr,
            }
        }
        return {
            src: `${asset?.url}?w=${width * dpr}`,
            width: width * dpr,
        }
    })

    const ratio = ((asset?.height || 1) / (asset?.width || 1)) * width

    return <GatsbyImage
        alt=""
        image={{
            images: {
                sources: [],
                fallback: {
                    src: sizes[sizes.length - 1].src,
                    srcSet: sizes.map(x => `${x.src} ${x.width}w`).join(",\n"),
                    sizes: `(min-width: ${width}px) ${width}px, 100vw`
                },
            },
            layout: 'constrained',
            backgroundColor: asset?.colors?.[0]?.rgb,
            width: width,
            height: ratio
        }}
        {...rest}
    />
}

export default MockedAsset
