import {graphql, useStaticQuery} from "gatsby";


const useShopifyIds = () => {
    const {
        allShopifyProduct: {
            nodes
        }
    } = useStaticQuery(graphql`
        query ShopifyIdStaticQuery {
            allShopifyProduct {
                nodes {
                    handle
                    defaultVariant
                }
            }
        }
    `)

    return nodes.reduce((acc, val) => {
        acc[val.handle] = val.defaultVariant || "MISSING"
        return acc;
    }, {})
}

export default useShopifyIds;
