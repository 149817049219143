import './reset.css';
import React from 'react'
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query"
import Header from '../components/header'
import Footer from '../components/footer'
import GridContextProvider from "../components/primitives/grid/context";
import Provider from "../components/primitives/provider";
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import './theme.scss';
import './fonts.css';
import Breadcrumbs from "../components/breadcrumbs";
import QuickQuoteContext from "../contexts/quick-quote-context";
import StoreContext from "../contexts/store-context";
import CompareContext, {useCompareContext} from "../contexts/compare-context";
import CompareView from "../components/product/compare-view";
import AllProductsContext from "../contexts/all-products-context";
import SearchContext, {useSearchContext} from "../contexts/search-context";
import SearchView from "../components/product/search-view";
import ToastContext from "../contexts/toast-context";

const MaybeTransition = ({ shouldChange, children }) => {

    const {
        isOpen
    } = useCompareContext()

    const {
        isOpen: searchIsOpen
    } = useSearchContext();


    return (
        <div>
            {searchIsOpen && (
                <SkipNavContent css={{ width: '100%' }} id="skip-nav" as="main">
                    <SearchView />
                </SkipNavContent>
            )}
            {(isOpen && !searchIsOpen) && (
                <SkipNavContent css={{ width: '100%' }} id="skip-nav" as="main">
                    <CompareView />
                </SkipNavContent>
            )}
            {(!isOpen && !searchIsOpen) && (
                <SkipNavContent css={{ width: '100%' }} id="skip-nav" as="main">
                    {children}
                    <Footer />
                </SkipNavContent>
            )}
        </div>
    )
}


const Layout = (props) => {

    const globalData = layoutQuery()

    if (props?.location?.pathname === "/importer/")  {
        return props.children
    }

    return (
        <AtollonProvider {...props} globalData={globalData}>
            <Provider>
                <GridContextProvider
                    breakpoints={{
                        dt: { query: '@media screen', columns: 12, gap: 28 },
                        tb: { query: '@media (max-width: 1200px)', columns: 12, gap: 28 },
                        mb: { query: '@media (max-width: 767px)', columns: 4, gap: 16 },
                    }}
                    maxWidth={1440}
                    desktopFirst
                >
                    <SkipNavLink contentId="skip-nav" />
                    <CompareContext>
                        <ToastContext>
                            <QuickQuoteContext>
                                <AllProductsContext>
                                    <StoreContext>
                                        <SearchContext>
                                            <div className="__audal_labs" css={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>

                                                <Header />
                                                <MaybeTransition shouldChange={props.location.pathname}>
                                                    <Breadcrumbs {...props} />
                                                    {props.children}
                                                </MaybeTransition>
                                            </div>
                                        </SearchContext>
                                    </StoreContext>
                                </AllProductsContext>
                            </QuickQuoteContext>
                        </ToastContext>
                    </CompareContext>
                </GridContextProvider>
            </Provider>
            <div id="modal-portal" />
        </AtollonProvider>
    )
}

export default Layout
