const retryWizard = async <T>(executor: () => Promise<T>, iterations = 30): Promise<T> => {
    let idx = 0;
    for await (const i of Array(iterations).keys()) {
        idx += 1;
        try {
            const z = await executor()
            return z;
        } catch (e) {
            console.warn(e, `Issue when trying to perform async action, retrying... (attempt ${idx})`)
        }
    }
    throw new Error(`Unable to perform async action. Tried ${iterations} times`)
}

export default retryWizard
