import React from "react";
import Container from "../primitives/grid/container";
import Typography from "../primitives/typography";
import { useOnClickOutside } from 'usehooks-ts'
import {useSearchContext} from "../../contexts/search-context";
import ProductList from "../structured-text/blocks/product-list";
import {colors} from "../primitives/colors";
import debounce from "debounce";
import {useLocation} from "@reach/router";

const SearchView = () => {

    const { setIsOpen, isOpen } = useSearchContext()
    const [searchTerm, setSearchTerm] = React.useState("")

    const ref = React.useRef<HTMLDivElement>(null)
    useOnClickOutside(ref, () => setIsOpen(false))

    const z = debounce(setSearchTerm, 400)

    const { pathname } = useLocation()
    const [firstPathname] = React.useState(pathname)

    React.useEffect(() => {
        if (isOpen && firstPathname !== pathname) {
            setIsOpen(false)
        }
    }, [pathname])

    return (
        <div ref={ref}>
            <Container>
                <Typography as="input" css={{
                    borderBottom: `1px solid ${colors.secondaryBeige3}`,
                    padding: '16px 0',
                    width: '100%',

                }} fontSize={{
                    dt: 's1'
                }} placeholder="Search our products" type="text" onChange={(e) => {
                    z(e.target.value)
                }} />
            </Container>
            <ProductList linkedProducts={[]} searchQuery={searchTerm} />
        </div>
    )

}

export default SearchView
