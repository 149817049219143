import React from 'react';

export interface FormLabelProps {
    children: string
    id: string
}

const FormLabel = ({ children, id }: FormLabelProps): JSX.Element => (
    <div>
        <label
            htmlFor={id}
            css={{
                opacity: '0',
                transition: '0.2s',
                fontSize: '14px',
                position: 'absolute',
                padding: '0 4px',
                top: '-8px',
                left: '12px',
                backgroundColor: 'var(--color-shades-parchment)',
                borderRadius: '500px',
                '@media (max-width: 767px)': {
                    fontSize: '12px',
                },
            }}
        >
            {children}
        </label>
    </div>
);

export default FormLabel;
