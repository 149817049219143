import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface FadeTransitionProps {
    shouldChange: string;
    className?: string;
    children: React.ReactNode
}

const contentInnerVariants = {
    enter: () => ({
        opacity: 0,
    }),
    idle: () => ({
        opacity: 1,
        transition: { duration: 0.5 },
    }),
    exit: () => ({
        opacity: 0,
        transition: { duration: 0.2 },
    }),
};

const FadeTransitionFramer = ({ className, children, shouldChange }: FadeTransitionProps): JSX.Element => (
    <AnimatePresence mode="wait" initial={false}>
        <motion.div
            key={shouldChange}
            variants={contentInnerVariants}
            exit="exit"
            animate="idle"
            initial="enter"
            className={className}
        >
            {children}
        </motion.div>
    </AnimatePresence>
);

export default FadeTransitionFramer;
