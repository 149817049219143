import React from 'react';

/**
 * Simple Accessible Alert component that can be re-used.
 * @alias HtmlPropsHTMLSpanElement>
 * */
const FormAlert = React.forwardRef<
HTMLSpanElement,
HtmlProps<HTMLSpanElement>
>((props, ref) => (
    <span
        ref={ref}
        role="alert"
        css={{
            display: 'block',
            color: 'var(--color-states-error)',
            paddingTop: '6px',
            fontSize: '16px',
            fontWeight: '600',
            '@media (max-width: 767px)': {
                fontSize: '14px',
            },
        }}
        {...props}
    />
));

FormAlert.displayName = 'FormAlert';

export default FormAlert;
