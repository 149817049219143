import {
    Branch,
    Group,
    IdSet, LeafAssignRule, LeafSizeRule,
    Measurement,
    Option,
    PriceCategory,
    PrimaryPrice, PrimaryVariant,
    Render,
    Select,
    Size, SizeAddition, SizeRule
} from "../../types";


const toSnakeCase = (str: string): string => {
    if (str === void 0) { str = ''; }
    if (!str)
        return '';
    return String(str)
        .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
        .replace(/([a-z])([A-Z])/g, function (m, a, b) { return a + '_' + b.toLowerCase(); })
        .replace(/[0-9]/g, function (m, a, b) {
            return m + "_";
        })
        .replace(/[^A-Za-z0-9]+|_+/g, '_')
        .toLowerCase();
}

export const toCamelCase = (str: string = ''): string => {
    if (!str) return '';

    return String(str)
        .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
        .replace(/[^A-Za-z0-9]+/g, '$')
        .replace(/([a-z])([A-Z])/g, (m, a, b) => `${a}$${b}`)
        .toLowerCase()
        .replace(/(\$)(\w)/g, (m, a, b) => b.toUpperCase());
}

const isValidObject = (obj: any) => typeof obj === "object" && obj

const snakeKeys = (obj: any): object|null => {
    if (!isValidObject(obj)) return null;

    const res: any = {};
    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        const nkey = toSnakeCase(key);
        if (isValidObject(value)) {
            value = snakeKeys(value);
        } else if (Array.isArray(value)) {
            value = [...value].map((v) => {
                let ret = v;
                if (isValidObject(v)) {
                    // object in array
                    ret = snakeKeys(v);
                } else if (Array.isArray(v)) {
                    // array in array
                    // workaround by using an object holding array value
                    const temp: any = snakeKeys({ key: v });
                    ret = temp.key;
                }
                return ret;
            });
        }
        res[nkey] = value;
    });

    return res;
}



export const itemTypeMap: Record<string, string> = {
    "option_block": "OPTION",
    "select_block": "SELECT",
    "size_block": "SIZE",
    "group_block": "GROUP",
    "measurement_block": "MEASUREMENT",
    "primary_price_block": "PRIMARY_PRICE",
    "primary_variant_block": "PRIMARY_VARIANT",
    "price_category_block": "PRICE_CATEGORY",
    "size_rule_block": "SIZE_RULE",
    "assign_rule_block": "ASSIGN_RULE",
    "size_addition_block": "SIZE_ADDITION",
    "branch_block": "BRANCH",
    "stem_block": "STEM",
    "leaf_size_rule_block": "LEAF_SIZE_RULE",
    "leaf_assign_rule_block": "LEAF_ASSIGN_RULE",
    "leaf_select_rule_block": "LEAF_SELECT_RULE",
    "global_block": "GLOBAL"
}

const gatsbyDataToIdSet = (data: (Omit<Group|Select|Option|Measurement|Size|Render|PrimaryPrice|PriceCategory|SizeRule|SizeAddition|PrimaryVariant|Global|Branch|LeafSizeRule|LeafAssignRule, "type"> & { __typename: string })[]): IdSet => {
    return Object.fromEntries((data || []).map((block) => {

        const rest = snakeKeys(block)

        const itemType = itemTypeMap[toSnakeCase(block.__typename.replace("DatoCms", ""))]

        if (itemType === "GROUP") {
           rest.parent_customizer_id = "GROUP"
        }

        return [
            rest.customizer_id,
            JSON.parse(JSON.stringify({
                ...rest,
                dato_block_id: null,
                type: itemType
            }, (key, value) => {
                if (typeof value === "object" && value && value.original_id) {
                    //return value.original_id
                }
                if (typeof value === "string" && value.startsWith("DatoCms")) {
                    return value.replace("DatoCms", "")
                }
                return value;
            }))
        ]
    })) as IdSet
}

export default gatsbyDataToIdSet;
