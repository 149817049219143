import {colors} from "./primitives/colors";
import VisuallyHidden from "./primitives/visually-hidden";
import React from "react";


const Hamburger = ({ as = "button", open, setOpen }: { as?: React.ElementType, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>}): JSX.Element => {
    const Component = as;
    return (
        <Component
            css={{
                width: 'auto',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}
            onClick={() => {
                setOpen(!open);
            }}
            data-open={open}
        >
            <VisuallyHidden>
                Toggle Menu
            </VisuallyHidden>
            <div
                css={{
                    width: '19px',
                    height: '0.5px',
                    marginBottom: '3.5px',
                    backgroundColor: 'currentColor',
                    transformOrigin: '45% center',
                    transition: '0.3s',
                }}
                style={{
                    transform: open ? 'rotate(45deg) scale(0.8) translate(8px, 6px)' : '',
                }}
            />
            <div
                css={{
                    width: '19px',
                    height: '0.5px',
                    marginBottom: '3.5px',
                    backgroundColor: 'currentColor',
                    transformOrigin: '45% center',
                    transition: '0.3s',
                }}
                style={{
                    transform: open ? 'rotate(45deg) scale(0)' : '',
                }}
            />
            <div
                css={{
                    width: '19px',
                    height: '0.5px',
                    marginBottom: '3.5px',
                    backgroundColor: 'currentColor',
                    transformOrigin: '45% center',
                    transition: '0.3s',
                }}
                style={{
                    transform: open ? 'rotate(45deg) scale(0)' : '',
                }}
            />
            <div
                css={{
                    width: '19px',
                    height: '0.5px',
                    backgroundColor: 'currentColor',
                    transformOrigin: '45% center',
                    transition: '0.3s',
                }}
                style={{
                    transform: open ? 'rotate(-45deg) scale(0.8) translate(4px, -2px)' : '',
                }}
            />
        </Component>
    )
}

export default Hamburger;
