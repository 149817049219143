import React from "react";
import Container from "./primitives/grid/container";
import StyledLink from "./primitives/styled-link";
import Typography from "./primitives/typography";
import {colors} from "./primitives/colors";
import {useLocation} from "@reach/router";

const nestParents = (item) => {
    if (!item) return [];

    return [
        ...nestParents(item?.treeParent || item?.parentCategoryPage),
        {
            title: item.title,
            url: item.permalink,
        },
    ].filter(x => !!x)
}


const Breadcrumbs = ({ data, pageContext }) => {

    const { pathname } = useLocation()

    if (pathname.startsWith("/customiser")) {
        return null
    }

    if (pathname === "/") {
        return (
            <div css={{ display: 'flex', gap: '6px', marginTop: '10px', marginBottom: 'calc(32px + 17px)' }} />
        )
    }


    const maybeData = (Object.values(data || {})?.[0] || {});

    const links = [
        { title: "Home", url: "/" },
        ...(() => {
            if (pathname.includes("/guide")) {
                return [
                    { title: "Guides", url: "/guides" }
                ]
            }
            if (pathname.includes("/articles")) {
                return [
                    { title: "Inspiration", url: "/articles" }
                ]
            }
            if (pathname.includes("/cart")) {
                return [
                    { title: "Your Cart", url: "/cart" }
                ]
            }
            return []
        })(),
        ...nestParents(maybeData)
    ].reduce((acc, val) => {
        if (acc.find(x => x.title === val.title)) {
            return acc
        }
        return [...acc, val]
    }, []).filter(x => x.title)


    return (
        <Container css={{
            '.no-menu &': {
                display: 'none'
            },
            '.white-logo &': {
                display: 'none'
            },
            '.page-404 &': {
                display: 'none'
            }
        }}>
           <div css={{ display: 'flex', gap: '6px', marginTop: '10px', marginBottom: '32px' }}>
               {links.map((link, idx) => (
                   <>
                       {idx !== 0 && (
                           <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M6.40039 13.2998L11.2004 8.4998L6.40039 3.6998" stroke="#333333" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                           </svg>
                       )}
                       <StyledLink to={link.url} css={{
                           color: colors.neutral9
                       }}>
                           <Typography fontSize={{
                               dt: "c1"
                           }} fontWeight="light">
                               {link.title}
                           </Typography>
                       </StyledLink>
                   </>
               ))}
           </div>
        </Container>
    )
}

export default Breadcrumbs
