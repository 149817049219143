import { Interpolation, Theme } from '@emotion/react';
import React from 'react';
import { GatsbyLinkProps } from 'gatsby';
import Button, { ButtonProps, buttonStyles } from '../button';
import { colors } from '../colors';
import {Link} from "../../../../plugins/gatsby-plugin-atollon";
import {breakpoints} from "../tokens";

export const styledButtonBaseStyles = {
    overflow: 'hidden',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1',
    fontSize: '16px',
    lineHeight: '21px',
    backgroundColor: colors.shadesWhite,
    border: `1px solid ${colors.shadesWhite}`,
    borderRadius: '500px',
    padding: '8px 30px',
    transition: '0.2s',
    '&.filled-orange': {
        backgroundColor: colors.primaryOrange,
        color: colors.shadesWhite,
        border: `1px solid ${colors.primaryOrange}`,
        '&:hover': {
            backgroundColor: colors.primaryOrangeHover,
        },
        '&:disabled': {
            backgroundColor: colors.neutral2,
            color: colors.neutral4,
            border: `1px solid ${colors.neutral2}`,
        }
    },
    '&.outlined-orange': {
        backgroundColor: colors.shadesWhite,
        color: colors.primaryOrange,
        border: `1px solid ${colors.primaryOrange}`,
        '&:hover': {
            backgroundColor: colors.primaryOrange3,
            border: `1px solid ${colors.primaryOrange3}`,
            color: colors.shadesWhite
        }
    },
    '&.filled-grey': {
        backgroundColor: colors.neutral8,
        color: colors.shadesWhite,
        border: `1px solid ${colors.neutral8}`,
        '&:hover': {
            backgroundColor: colors.neutral9,
            border: `1px solid ${colors.neutral9}`,
            color: colors.shadesWhite
        }
    },
    '&.outlined-grey': {
        backgroundColor: 'transparent',
        color: colors.neutral10,
        border: `1px solid ${colors.neutral5}`
    },
    '&.outlined-blue': {
        backgroundColor: 'transparent',
        color: colors.tertiaryBlue5,
        border: `1px solid ${colors.tertiaryBlue5}`
    },
    '&.fill-white': {
        backgroundColor: colors.shadesWhite,
        color: colors.neutral9,
        border: `1px solid ${colors.shadesWhite}`,
        '&:hover': {
            backgroundColor: colors.neutral1,
        },
        '&:disabled': {
            backgroundColor: colors.neutral2,
            color: colors.neutral4,
            border: `1px solid ${colors.neutral2}`,
        }
    },
    '&.help-callout': {
        color: colors.shadesBlack,
        padding: '10px 20px',
        textAlign: 'left',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        '&.active': {
            backgroundColor: colors.secondaryBeige2,
            border: `1px solid ${colors.secondaryBeige2}`
        }
    },
    '&.filled-beige': {
        backgroundColor: colors.secondaryBeige2,
        color: colors.neutral10,
        border: `1px solid ${colors.secondaryBeige2}`
    },
    '&.filled-blue': {
        backgroundColor: colors.tertiaryBlue2,
        color: colors.neutral10,
        border: `1px solid ${colors.tertiaryBlue2}`,
        '&:hover': {
            backgroundColor: colors.tertiaryBlue3,
            border: `1px solid ${colors.tertiaryBlue3}`,
            color: colors.neutral10
        }
    },
    '&.icon-button': {
        span: {
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            justifyContent: 'center',
        },
        backgroundColor: 'transparent',
        padding: '8px 25px',
        border: `1px solid ${colors.neutral5}`,
        [breakpoints.mb]: {
            padding: '8px 20px'
        },
        '&.active': {
            border: `1px solid ${colors.neutral10}`,
            backgroundColor: colors.shadesWhite
        },
        '.atl-asset': {
            width: '28px',
            height: '28px',
            display: 'inline-block',
            [breakpoints.mb]: {
                width: '20px',
                height: '20px'
            }
        }
    }
} as Interpolation<Theme>;

type buttonAttributes = { className: 'filled-blue'|'filled-orange'|'outlined-orange'|'outlined-grey'|'fill-white'|'help-callout'|'filled-beige'|string }

const StyledButton = (props: NoRef<ButtonProps> & buttonAttributes): JSX.Element => (
    <Button
        css={styledButtonBaseStyles}
        {...props}
    />
);

export const StyledButtonAsLink = (props: NoRef<GatsbyLinkProps<any>> & buttonAttributes): JSX.Element => (
    // @ts-ignore
    <Link
        css={{
            ...buttonStyles as any,
            ...styledButtonBaseStyles as any,
        }}
        {...props}
    />
);

export default StyledButton;
