import React from 'react';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';
import useDynamicImports, {LoadingStrategy} from "../../utilities/use-dynamic-imports"
import { useInView } from "react-intersection-observer";

interface DatoAssetProps {
    alt?: string
    asset?: any
    thumbnail?: any
    style?: React.CSSProperties
    className?: string
    objectFit?: React.CSSProperties['objectFit'];
    objectPosition?: React.CSSProperties['objectPosition'];
    autoPlay?: boolean;
    muted?: boolean;
    isStatic?: boolean
    onPlayStateChange?: (state: 'play' | 'pause') => void
    onExitFullscreen?: () => void
    loading?: "eager" | "lazy"
}

const videoStateIsPlaying = (videoElement) => {
    return (videoElement.currentTime > 0 && !videoElement.paused && !videoElement.ended && videoElement.readyState > 2)
}

const classNames = (...classes) => (classes as string[]).filter(x => !!x).map(x => x.trim()).join(" ")

const VideoHandler = ({
                          alt,
                          asset,
                          style,
                          className,
                          objectFit = 'cover',
                          objectPosition: overrideObjPosition,
                          autoPlay = false,
                          muted,
                          isStatic,
                          thumbnail,
                          onPlayStateChange,
                          onExitFullscreen,
                          defaultStyles,
                      }: DatoAssetProps & { defaultStyles: any }) => {

    const reactPlayerRef = React.useRef();
    const [isPlaying, setIsPlaying] = React.useState(autoPlay && muted);


    const fullScreenEv = React.useCallback(() => {
        if (!document.fullscreenElement) {
            if (onExitFullscreen) {
                onExitFullscreen();
            }
            if (!muted && !asset.provider) {
                reactPlayerRef.current.getInternalPlayer().pause()
            }
        }
    }, [onExitFullscreen]);

    React.useEffect(() => {
        if (reactPlayerRef?.current) {
            window.addEventListener('fullscreenchange', fullScreenEv);
        }

        return () => {
            window.removeEventListener('fullscreenchange', fullScreenEv);
        };
    }, [fullScreenEv]);

    const {
        hasLoaded,
        scripts: {
            ReactPlayer,
        },
        load,
    } = useDynamicImports({
        ReactPlayer: () => import('react-player'),
    }, LoadingStrategy.Interaction)

    const {
        ref,
        inView
    } = useInView()

    if (isStatic || !hasLoaded) {
        return (
            <img
                ref={ref}
                alt=""
                src={thumbnail?.gatsbyImageData?.images?.fallback?.src || asset?.thumbnailUrl || asset?.video?.thumbnailUrl || ''}
                className={classNames('atl-asset', className)}
                style={defaultStyles}
            />
        );
    }

    return (
        <div
            role="button"
            className={classNames('atl-asset', className)}
            style={defaultStyles}
            data-playing={isPlaying}
            onTouchEnd={() => {
                if (!muted && !asset.provider) {
                    if (videoStateIsPlaying(reactPlayerRef.current.getInternalPlayer())) {
                        try {
                            if (document.fullscreenElement) {
                                document.exitFullscreen()
                            }
                            //reactPlayerRef.current.getInternalPlayer().pause()
                        } catch (e) {
                        }
                    } else {
                        if (!asset.provider) {
                            reactPlayerRef.current.getInternalPlayer().play()
                            reactPlayerRef.current.getInternalPlayer().requestFullscreen()
                        }
                    }
                }
            }}
            onMouseUp={() => {
                if (!muted && !asset.provider) {
                    if (videoStateIsPlaying(reactPlayerRef.current.getInternalPlayer())) {
                        //reactPlayerRef.current.getInternalPlayer().pause()
                    } else {
                        reactPlayerRef.current.getInternalPlayer().play()
                    }
                }
            }}
        >
            <ReactPlayer
                ref={reactPlayerRef as React.Ref<any>}
                url={asset?.video?.streamingUrl || asset?.url || asset?.video?.url || undefined}
                config={{
                    attributes: {
                        poster: thumbnail?.gatsbyImageData?.images?.fallback?.src || asset?.thumbnailUrl || asset?.video?.thumbnailUrl,
                    },
                } as any}
                playing={autoPlay}
                controls={(
                    !(autoPlay && muted)
                ) && isPlaying}
                playsinline={muted}
                loop={autoPlay && muted}
                muted={muted}
                width="100%"
                height="100%"
                onReady={(e) => {
                    if (!muted) {
                        try {
                            //e.getInternalPlayer().play();
                            // eslint-disable-next-line no-empty
                        } catch (e) {
                        }
                    }
                }}
                onPlay={() => {
                    setIsPlaying(true)
                    if (onPlayStateChange) {
                        onPlayStateChange('play');
                    }
                }}
                onPause={() => {
                    setIsPlaying(false)
                    if (onPlayStateChange) {
                        onPlayStateChange('pause');
                    }
                }}
                onEnded={() => {
                    setIsPlaying(false)
                    if (onPlayStateChange) {
                        onPlayStateChange('pause');
                    }
                }}
            />
            {(!!asset?.thumbnailUrl && !isPlaying) && (
                <img
                    alt=""
                    src={thumbnail?.gatsbyImageData?.images?.fallback?.src || asset?.thumbnailUrl || asset?.video?.thumbnailUrl || ''}
                    className={classNames('atl-asset', className)}
                    style={{
                        ...defaultStyles,
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        pointerEvents: 'none'
                    }}
                />
            )}
            {(!autoPlay && !muted && (!asset?.provider || (asset?.provider && asset?.thumbnailUrl))) && (
                <div className="play">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24" cy="24" r="24" fill="white"/>
                        <path d="M18.4181 34.3369C18.4884 34.3744 18.5662 34.3932 18.6431 34.3932C18.7368 34.3932 18.8308 34.3662 18.9114 34.3119L33.6263 24.3987C33.7585 24.3087 33.8382 24.1596 33.8382 24.0002C33.8382 23.8399 33.7585 23.6908 33.6263 23.6018L18.9122 13.6886C18.765 13.5901 18.5746 13.5798 18.4181 13.6633C18.2606 13.7467 18.1631 13.9098 18.1631 14.087V33.9132C18.1631 34.0904 18.2615 34.2535 18.4181 34.3369Z" fill="#0A1739"/>
                    </svg>
                </div>
            )}
        </div>
    );
}

const DatoAsset = (props: DatoAssetProps): JSX.Element => {

    const {
        alt,
        asset,
        style,
        className,
        objectFit = 'cover',
        objectPosition: overrideObjPosition,
        autoPlay = false,
        muted,
        isStatic,
        thumbnail,
        onPlayStateChange,
        onExitFullscreen,
        loading,
    } = props;

    // Is an internal asset
    const objectPosition = overrideObjPosition || asset?.objectPosition;

    const defaultStyles = {
        objectFit,
        objectPosition,
        '--object-fit': objectFit,
        '--object-position': objectPosition,
        pointerEvents: muted ? 'none' : 'all',
        aspectRatio: (asset?.width && asset?.height && !asset.isImage) ? asset.width/asset.height : 'unset',
        ...(style || {}),
    }

    if (!asset) {
        return (
            <div
                className={classNames('atl-asset', className)}
                style={defaultStyles}
            />
        );
    }

    if (asset && 'isImage' in asset) {
        const generatedAltData = asset?.smartTags && asset.smartTags.length > 0 ? `Image of ${asset.smartTags[0]}` : '';

        if (asset.format === 'svg') {
            return (
                <img
                    alt={alt || asset.alt || generatedAltData}
                    src={asset.url || undefined}
                    className={classNames('atl-asset', className)}
                    style={defaultStyles}
                />
            );
        }

        if (asset.isImage && asset.gatsbyImageData) {
            return (
                <GatsbyImage
                    style={defaultStyles}
                    className={classNames('atl-asset', className)}
                    alt={alt || asset.alt || generatedAltData}
                    image={asset.gatsbyImageData as unknown as IGatsbyImageData}
                    objectFit={objectFit}
                    objectPosition={objectPosition}
                    loading={loading}
                />
            );
        }
    }

    if (asset?.video || (asset?.provider && asset?.url)) {
        return <VideoHandler defaultStyles={defaultStyles} {...props} />
    }

    // Nothing exists

    return (
        <div
            className={classNames('atl-asset', className)}
            style={defaultStyles}
        />
    );
};

export default DatoAsset
