import {graphql, useStaticQuery} from "gatsby";
import {IconDataSet} from "../importer/components/product-editor";


const useIconsQuery = (): IconDataSet => {

    const {
        allIcons
    } = useStaticQuery(graphql`
        query IconsStaticQuery {
            allIcons
        }
    `)

    return allIcons
}

export default useIconsQuery
