import React from "react";
import {ProductRenderDisplayVariant} from "../importer/customizer/utils/get-variants-for-display";

interface SearchContext {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Ctx = React.createContext<SearchContext>(null as unknown as SearchContext)

const SearchContext = ({ children }: React.PropsWithChildren): JSX.Element => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false)
    const value = React.useMemo(() => ({
        isOpen,
        setIsOpen,
    }), [isOpen, setIsOpen])
    return (
        <Ctx.Provider value={value}>
            {children}
        </Ctx.Provider>
    )
}

export const useSearchContext = (): SearchContext => React.useContext(Ctx)

export default SearchContext
