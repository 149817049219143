import React, { useRef} from "react"
import Container from "./primitives/grid/container";
import {colors} from "./primitives/colors";
import Typography from "./primitives/typography";
import {useInView} from "framer-motion";
import DesktopHeader from "./desktop-header";
import MobileHeader from "./mobile-header";
import {graphql, useStaticQuery} from "gatsby";
import VisuallyHidden from "./primitives/visually-hidden";





const Header = () => {

    const ref = useRef()
    const inView = useInView(ref)

    const {
        datoCmsSiteOptionPage: {
            headingAlertCtaContent,
            showHeadingAlertCta
        }
    } = useStaticQuery(graphql`
        {
            datoCmsSiteOptionPage {
                headingAlertCtaContent
                showHeadingAlertCta
            }
        }
    `)

    const [alertHidden, setAlertHidden] = React.useState(!showHeadingAlertCta)

    return (
        <>
            {!alertHidden && (
                <div css={{
                    background: colors.primaryOrange,
                    textAlign: 'center',
                    color: colors.shadesWhite,
                    padding: '6px 0',
                    zIndex: 10000,
                    '.no-menu &': {
                        display: 'none'
                    },
                }}>
                    <Container>
                        <div css={{
                            position: 'relative'
                        }}>
                            <div css={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                            }}>
                                <button type="button" onClick={() => {
                                    setAlertHidden(true)
                                }}>
                                    <VisuallyHidden>Close Alert</VisuallyHidden>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.8 3.2002L3.19995 12.8002M3.19995 3.2002L12.8 12.8002" stroke="white" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <Typography fontSize={{
                                dt: 'c1'
                            }} fontWeight="light">
                                {headingAlertCtaContent}
                            </Typography>
                        </div>
                    </Container>
                </div>
            )}
            <div ref={ref} />
            <header css={{
                position: 'sticky',
                top: '0',
                zIndex: 1000,
                backgroundColor: colors.shadesWhite,
                transition: 'box-shadow 0.2s',
                '.white-logo &': {
                    boxShadow: 'none!important',
                    backgroundColor: 'transparent',
                    height: '0px',
                },
            }} style={{
                boxShadow: inView ? "0px 4px 14px rgba(0, 0, 0, 0), 0px 4px 14px rgba(0, 0, 0, 0)" : "0px 4px 14px rgba(0, 0, 0, 0.06), 0px 4px 14px rgba(0, 0, 0, 0.06)"
            }}>
                <DesktopHeader />
                <MobileHeader />
            </header>
        </>
    )
}

export default Header
