import slugify from "slugify";
import getPriceForProduct from "./get-price-for-product";
import {
    DefinitionSet, FilterableProductTag,
    Group, IdAsArray, IdSet,
    Measurement,
    Option, ParentIncludingProductTag,
    PriceCategory,
    PrimaryPrice, PrimaryVariant,
    Render,
    Select, Selections,
    Size, SizeAddition, SizeRule
} from "../../types";
import gatsbyDataToIdSet from "./gatsby-data-to-id-set";

interface KeyFact {
    key: string
    value: string
    groupId: string
}


export interface ProductRenderDisplayVariant {
    index: number,

    handle: string
    freeSampleHandle: string
    customizerBase: string
    customizerPermalink: string
    permalink: string

    furnishingTypeDefinitionOriginalId: string
    productDefinitionOriginalId: string
    fabricDefinitionOriginalId: string
    colorDefinitionOriginalId: string

    fabricSelectCustomizerId: string
    fabricOptionCustomizerId: string
    colorSelectCustomizerId: string
    colorOptionCustomizerId: string

    furnishingTypeTitle: string
    productTitle: string
    fabricTitle: string
    colorTitle: string

    productDescription?: string
    fabricDescription?: string
    colorDescription?: string

    fabricVariantDescription?: string
    colorVariantDescription?: string

    warranty: string
    leadTime: string
    fabricComposition: string
    careInstructions: string
    colorFamily: string

    furnishingTypeIcon: any
    furnishingTypeIconInvert: any
    icon: any
    iconInvert: any
    image: any
    showPill: boolean
    pillText: string

    selections: Selections
    realSelections: Selections // Used to setup the customizer

    renderImages: string[]
    productImages: string[]

    tags: ParentIncludingProductTag[]
    keyFacts: KeyFact[]

    price: string
    numericPrice: number

    related: string[]
}

export interface ProductPartial {
    data?: (Omit<Group | Select | Option | Measurement | Size | Render | PrimaryPrice | PriceCategory | SizeRule | SizeAddition | PrimaryVariant, "type"> & {
        __typename: string
    })[];
    dataJson?: (Omit<Group | Select | Option | Measurement | Size | Render | PrimaryPrice | PriceCategory | SizeRule | SizeAddition | PrimaryVariant, "type"> & {
        __typename: string
    })[];
    datoData?: IdSet
    title: string
    originalId: string
    description: string
    permalink: string
    icon: { url: string }
    iconInverted: { url: string }
    furnishingType: string
    productTags: string[]
}

interface GetVariantsForDisplayProps {
    fieldDefinitions: DefinitionSet
    product: ProductPartial
    optionalValues?: Selections
    parsedPriceDefinitions?: Record<string, unknown>
    images: ImageKeySet
    isDemoMode?: boolean
}

export type ImageKeySet = Record<string, string>

export const compileTags = ({
    fieldDefinitions,
    item,
    parentTags = [],
}: {
    fieldDefinitions: DefinitionSet,
    item: Option,
    parentTags?: ParentIncludingProductTag[]
}): ParentIncludingProductTag[] => {

    let tags: FilterableProductTag[] = []

    if (item && item.option_definition) {
        if (fieldDefinitions.optionDefinitions[item.option_definition]?.filter_groups) {
            tags = [
                ...fieldDefinitions.optionDefinitions[item.option_definition].filter_groups
                    .map(x => fieldDefinitions.filterableProductTags[x])
                    .filter(x => !!x),
                ...tags,
            ]
        }
    }

    const newTags: { [key: string]: ParentIncludingProductTag } = {};

    parentTags.forEach((tag) => {
        newTags[tag.parentGroupId] = tag
    })

    tags.forEach((tag) => {

        const foundParent = fieldDefinitions.filterableProductTagGroups[tag.group]

        if (!foundParent) {
            throw new Error("Missing Tag Parent")
        }

        newTags[tag.group] = {
            title: tag.title,
            id: tag.id,
            parentGroupId: foundParent.id,
            parentGroupTitle: foundParent.title,
            isFilterable: foundParent.is_filterable,
        }
    })

    return Object.values(newTags);
}


export const compileKeyFacts = ({
    warranty,
    fieldDefinitions,
    item,
    parentKeyFacts = []
}: {
    warranty: string,
    fieldDefinitions: DefinitionSet,
    item: Option,
    parentKeyFacts?: KeyFact[]
}): KeyFact[] => {
    let facts: KeyFact[] = [
        { key: 'Warranty', value: warranty, groupId: "*WARRANTY" }
    ]

    if (item && item.option_definition) {
        if (fieldDefinitions.optionDefinitions[item.option_definition]?.filter_groups) {
            facts = [
                ...(fieldDefinitions.optionDefinitions[item.option_definition].filter_groups
                    .map(x => fieldDefinitions.filterableProductTags[x])
                    .filter(x => !!x)
                    .filter(x =>
                        fieldDefinitions.filterableProductTagGroups[x.group]
                        && fieldDefinitions.filterableProductTagGroups[x.group].present_as_key_fact
                    )
                    .map(x => {
                        return {
                            key: fieldDefinitions.filterableProductTagGroups[x.group].title,
                            value: x.title,
                            groupId: fieldDefinitions.filterableProductTagGroups[x.group].id,
                        }
                    })),
                ...facts
            ]
        }
    }


    // combine with parent if it exists

    const newFacts: { [key: string]: KeyFact } = {};

    parentKeyFacts.forEach((fact) => {
        newFacts[fact.groupId] = fact
    })

    facts.forEach((fact) => {
        newFacts[fact.groupId] = fact
    })

    return Object.values(newFacts);
}

const getVariantsForDisplay = ({
                                   fieldDefinitions,
                                   product,
                                   optionalValues,
                                   parsedPriceDefinitions,
                                   images,
                                   isDemoMode,
                                 }: GetVariantsForDisplayProps): ProductRenderDisplayVariant[] => {
    const data = product.data || product.dataJson || product.datoData
    if (data === undefined) {
        return []
    }


    const ids = product.datoData ? product.datoData : gatsbyDataToIdSet(data)
    const idsAsArray = Object.values(ids)

    if ([
        data,
        product.title,
        product.originalId,
        product.description,
        product.permalink,
        product.icon,
        product.furnishingType,
    ].some(x => x === undefined || x === null)) {
        console.error(product)
        throw new Error(`getVariantsForDisplay Invariant Error`)
    }

    if (typeof product.furnishingType === "object") {
        throw new Error("Furnishing Type must be a string")
    }

    const foundFurnishingType = fieldDefinitions.furnishingTypes[product.furnishingType];

    if (!foundFurnishingType) {
        console.log(fieldDefinitions.furnishingTypes)
        console.log(product.furnishingType)
        throw new Error("Missing Furnishing Type")
    }


    const furnishingTypeIcon = foundFurnishingType.icon;
    const furnishingTypeIconInvert = foundFurnishingType.icon_inverted;


    const iconToUse = product.icon;
    const iconToUseInvert = product.iconInverted;

    const foundPrimaryVariant = idsAsArray.find(x => {
        return x.type === "PRIMARY_VARIANT"
    })

    const foundSelectVariant = idsAsArray.find(x => {
        if (x.type === "SELECT") {
            return foundPrimaryVariant?.parent_customizer_id === x.customizer_id
        }
    }) as Select | undefined


    if (!foundSelectVariant) {
        return []
    }

    const foundSelectVariantOptions = idsAsArray.filter(x => {
        if (x.type === "OPTION" && x.parent_customizer_id === foundSelectVariant.customizer_id) {
            return true
        }
    }) as Option[]

    const productLevelTags = product.productTags
        .map(x => fieldDefinitions.filterableProductTags[x])
        .map(x => {
            const parent = fieldDefinitions.filterableProductTagGroups[x.group]
            const z: ParentIncludingProductTag = {
                parentGroupId: parent.id,
                parentGroupTitle: parent.title,
                isFilterable: parent.is_filterable,
                id: x.id,
                title: x.title,
            }
            return z
        })

    return foundSelectVariantOptions.sort((a, b) => a.index - b.index).reduce((acc, item) => {

        if (!item.option_definition) {
            return acc;
        }

        const fabricOptionDefinition = fieldDefinitions.optionDefinitions[item.option_definition];

        if (!fabricOptionDefinition) {
            return acc;
        }

        const foundPrimaryColourFields = idsAsArray.filter(x => {
            if (
                x.type === "SELECT"
                && x.select_definition
                && fieldDefinitions.selectDefinitions[x.select_definition]
                && (fieldDefinitions.selectDefinitions[x.select_definition].option_select_type === "color" || fieldDefinitions.selectDefinitions[x.select_definition].option_select_type === "color_desc")
                && x.customizer_id !== foundSelectVariant.customizer_id
            ) {
                return true
            }
        }) as Select[]

        const foundPrimaryColourField = (() => {
            const maybeParent = foundPrimaryColourFields.find(x => x.parent_customizer_id === item.customizer_id);
            if (maybeParent) {
                return maybeParent
            }
            return foundPrimaryColourFields[0]
        })()

        if (!foundPrimaryColourField) {
            return acc;
        }

        const colorVariationsViaPrimaryColorField = idsAsArray.filter(x => {
            if (
                x.type === "OPTION"
                && x.parent_customizer_id === foundPrimaryColourField.customizer_id
            ) {
                return true
            }
        }) as Option[]

        const otherFabricColorKvs: Record<string, string> = {};

        (idsAsArray.filter(x => {
            if (x.type === "SELECT" && x.select_definition && x.customizer_id !== foundSelectVariant.customizer_id) {
                const relatedSelectDefinition = fieldDefinitions.selectDefinitions[x.select_definition]
                if (relatedSelectDefinition && relatedSelectDefinition.option_select_type === "color_desc") {
                    return true
                }
            }
        }) as Select[])
            .forEach(foundSelectVariant => {
                const firstSelectVariantOption = idsAsArray.find(x => {
                    if (x.type === "OPTION" && x.parent_customizer_id === foundSelectVariant.customizer_id) {
                        return true
                    }
                }) as Option

                if (firstSelectVariantOption) {
                    const insideColorSelect = idsAsArray.find(x => {
                        if (x.type === "SELECT" && x.parent_customizer_id === firstSelectVariantOption.customizer_id) {
                            return true
                        }
                    }) as Select

                    if (insideColorSelect) {
                        const insideColorOption = idsAsArray.find(x => {
                            if (x.type === "OPTION" && x.parent_customizer_id === insideColorSelect.customizer_id) {
                                return true
                            }
                        }) as Option

                        if (insideColorOption) {
                            otherFabricColorKvs[foundSelectVariant.api_key] = firstSelectVariantOption.api_value;
                            otherFabricColorKvs[insideColorSelect.api_key] = insideColorOption.api_value;
                        }
                    }
                }
            });

        const fabricLevelKeyFacts = compileKeyFacts({
            warranty: foundFurnishingType.warranty,
            fieldDefinitions,
            item
        })

        const fabricLevelTags = compileTags({
            fieldDefinitions,
            item,
            parentTags: productLevelTags,
        })

        const productPaths = [
            ...(product.permalink.split("/")),
        ].filter(x => !!x)

        const shouldSplit = productPaths.length === 3

        const pathSegments = [
            ...productPaths,
            slugify(fieldDefinitions.optionDefinitions[item.option_definition].title, { lower: true, strict: true })
        ].filter(x => !!x)

        const listOfFinalizedVariants: ProductRenderDisplayVariant[] = []

        const customizerBaseHandle = [
            product.title,
            fabricOptionDefinition.title,
        ].map(x => slugify(x, { lower: true, strict: true })).join("-")

        colorVariationsViaPrimaryColorField.sort((a,b) => a.index - b.index).forEach((x, idx) => {
            if (x.option_definition) {

                const foundOption = fieldDefinitions.optionDefinitions[x.option_definition];

                if (!foundOption) {
                    console.log(`MISSING OPTION for`, x)
                    return;

                }

                const baseHandle = [
                    product.title,
                    fabricOptionDefinition.title,
                    foundOption.title
                ].map(x => slugify(x, { lower: true, strict: true })).join("-")

                const selections = {
                    [foundSelectVariant.api_key]: item.api_value,
                    [foundPrimaryColourField.api_key]: x.api_value,
                    ...otherFabricColorKvs,
                    ...(optionalValues || {}),
                }

                const realSelections = {
                    [foundSelectVariant.api_key]: item.api_value,
                    [foundPrimaryColourField.api_key]: x.api_value,
                    ...(optionalValues || {}),
                }

                const { price, numericPrice, sizeInvalid, selectionsInfo } = getPriceForProduct({
                    fieldDefinitions,
                    ids,
                    idsAsArray,
                    selections,
                    roomName: "Room",
                    parsedPriceDefinitions,
                    isDemoMode
                })

                const foundCareInstructions = (() => {
                    if (foundOption.care_instructions) {
                        return fieldDefinitions.careInstructions[foundOption.care_instructions]?.instructions
                    }
                    if (fabricOptionDefinition.care_instructions) {
                        return fieldDefinitions.careInstructions[fabricOptionDefinition.care_instructions]?.instructions
                    }
                    return ""
                })()

                if (!sizeInvalid) {

                    const optionTags = compileTags({
                        fieldDefinitions,
                        item: x,
                        parentTags: fabricLevelTags,
                    })

                    const colorOption: ProductRenderDisplayVariant = {
                        index: idx,

                        handle: baseHandle,
                        freeSampleHandle: `${baseHandle}-free-sample`,
                        customizerBase: customizerBaseHandle,
                        customizerPermalink: `/customiser/${baseHandle}`,
                        permalink: `/${baseHandle}`,

                        furnishingTypeDefinitionOriginalId: foundFurnishingType.id,
                        productDefinitionOriginalId: product.originalId,
                        fabricDefinitionOriginalId: fabricOptionDefinition.id,
                        colorDefinitionOriginalId: foundOption.id,

                        fabricOptionCustomizerId: item.customizer_id,
                        fabricSelectCustomizerId: foundSelectVariant.customizer_id,
                        colorOptionCustomizerId: x.customizer_id,
                        colorSelectCustomizerId: foundPrimaryColourField.customizer_id,

                        furnishingTypeTitle: foundFurnishingType.title,
                        productTitle: product.title,
                        fabricTitle: fabricOptionDefinition.title,
                        colorTitle: foundOption.title,

                        productDescription: product.description,
                        fabricDescription: fabricOptionDefinition.description,
                        colorDescription: foundOption.description,

                        fabricVariantDescription: fabricOptionDefinition.variant_description,
                        colorVariantDescription: foundOption.variant_description,

                        // Should lead time and fabric care etc also be here?

                        warranty: foundFurnishingType.warranty,
                        leadTime: (() => {
                            if (process.env.GATSBY_LEAD_TIME_TAG_ID) {
                                const found = optionTags.find(x => x.parentGroupId === process.env.GATSBY_LEAD_TIME_TAG_ID)
                                if (found) {
                                    return found.title
                                }
                            }
                            return ""
                        })(),
                        fabricComposition: (() => {
                            if (process.env.GATSBY_FABRIC_COMPOSITION_TAG_ID) {
                                const found = optionTags.find(x => x.parentGroupId === process.env.GATSBY_FABRIC_COMPOSITION_TAG_ID)
                                if (found) {
                                    return found.title
                                }
                            }
                            return ""
                        })(),
                        colorFamily: (() => {
                            if (process.env.GATSBY_COLOR_FAMILY_TAG_ID) {
                                const found = optionTags.find(x => x.parentGroupId === process.env.GATSBY_COLOR_FAMILY_TAG_ID)
                                if (found) {
                                    return found.title
                                }
                            }
                            return ""
                        })(),
                        careInstructions: foundCareInstructions,

                        furnishingTypeIcon: furnishingTypeIcon,
                        furnishingTypeIconInvert: furnishingTypeIconInvert,
                        icon: iconToUse,
                        iconInvert: iconToUseInvert,
                        image: foundOption.image,
                        showPill: foundOption.show_pill,
                        pillText: foundOption.pill_text || "",
                        selections,
                        realSelections,
                        renderImages: [
                            images[baseHandle]
                        ],
                        productImages: [
                            ...((fabricOptionDefinition.gallery_images || []).map(x => x.url)),
                            ...((foundOption.gallery_images || []).map(x => x.url))
                        ],
                        tags: optionTags,
                        keyFacts: compileKeyFacts({
                            warranty: foundFurnishingType.warranty,
                            fieldDefinitions,
                            item: x,
                            parentKeyFacts: fabricLevelKeyFacts,
                        }),
                        price,
                        numericPrice,

                        related: [],
                    }

                    listOfFinalizedVariants.push(colorOption)
                }
            }
        })

        listOfFinalizedVariants.forEach((variant) => {
            variant.related = listOfFinalizedVariants.filter(x => x.handle !== variant.handle).map(x => {
                return x.handle
            })
        })

        return acc.concat(listOfFinalizedVariants)

    }, [] as ProductRenderDisplayVariant[])
}




export default getVariantsForDisplay;
