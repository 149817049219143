import React, {PropsWithChildren} from "react";

const QUICK_QUOTE_STORAGE_KEY = "shadey_qq"

interface QuickQuoteContext {
    width: number|null
    setWidth: React.Dispatch<React.SetStateAction<number|null>>
    height: number|null
    setHeight: React.Dispatch<React.SetStateAction<number|null>>
}

const Ctx = React.createContext<QuickQuoteContext>(null as unknown as QuickQuoteContext)

const QuickQuoteContext = ({ children }: PropsWithChildren) => {

    const [width, setWidth] = React.useState<number|null>(null)
    const [height, setHeight] = React.useState<number|null>(null)
    const [hydrated, setHydrated] = React.useState(false)
    React.useEffect(() => { setHydrated(true) })
    React.useEffect(() => {
        if (!hydrated) {
            try {
                const data = localStorage.getItem(QUICK_QUOTE_STORAGE_KEY)
                if (data) {
                    const {
                        width: w,
                        height: h
                    } = JSON.parse(data)
                    if (w && h) {
                        setWidth(w)
                        setHeight(h)
                    }
                }
            } catch (e) {

            }
        } else {
            try {
                localStorage.setItem(QUICK_QUOTE_STORAGE_KEY, JSON.stringify({
                    width,
                    height
                }))
            } catch (e) {

            }
        }
    }, [hydrated, width, height])

    const value = React.useMemo(() => ({
        width,
        setWidth,
        height,
        setHeight,
    }), [
        width,
        setWidth,
        height,
        setHeight,
    ])

    return <Ctx.Provider value={value}>{children}</Ctx.Provider>
}

export const useQuickQuoteContext = (): QuickQuoteContext => React.useContext(Ctx)

export default QuickQuoteContext
