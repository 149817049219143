import React, {PropsWithChildren} from 'react';
import {colors} from "../colors";
import {motion} from "framer-motion";
import {breakpoints} from "../tokens";
import {createPortal} from "react-dom";
import {css} from "@emotion/react";

interface IModalContext {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalContext = React.createContext<IModalContext>(null as unknown as IModalContext)

export const Modal = ({
    forceOpen,
    setForceOpen,
    ...props
                      }: PropsWithChildren<{ forceOpen?: boolean, setForceOpen?: React.Dispatch<React.SetStateAction<boolean>>}>) => {
    const [isOpen, setIsOpen] = React.useState(forceOpen || false)

    React.useEffect(() => {
        if (typeof forceOpen === "boolean") {
            setIsOpen(forceOpen)
        }
    }, [forceOpen])

    React.useEffect(() => {
        if (typeof forceOpen === "boolean" && forceOpen !== isOpen && setForceOpen) {
            setForceOpen(isOpen)
        }
    }, [isOpen])

    const value = React.useMemo(() => ({
        isOpen,
        setIsOpen
    }), [
        isOpen,
        setIsOpen
    ])

    React.useEffect(() => {
        const scrollbarSize = window.innerWidth - document.body.clientWidth;
        if (isOpen) {
            document.body.style.overflowY = "hidden"
            document.body.style.marginRight = `${scrollbarSize}px`
        } else {
            document.body.style.overflowY = "";
            document.body.style.marginRight = "0px"
        }
    }, [isOpen])

    return (
        <ModalContext.Provider {...props} value={value} />
    )
}
export const ModalTrigger = (props: { children: React.ReactNode, asChild?: boolean }) => {
    const ctx = React.useContext(ModalContext)


    if (props?.asChild) {
        return React.Children.map(props.children, (child) => {
            if (typeof child === "object" && child) {
                return React.cloneElement(child, {
                    onClick: () => {
                        ctx.setIsOpen(true)
                    }
                })
            }
            return child;
        })
    }

    return (
        <button onClick={() => {
            ctx.setIsOpen(true)
        }} {...props} />
    )
}

/* This component houses the modal content */

const styles = {
    a1: css({
        backgroundColor: '#0008',
        backdropFilter: 'blur(2px)',
        position: 'fixed',
        zIndex: 2000000,
        padding: '60px 0',
        inset: 0,
        overflowY: 'auto',
        display: 'grid',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
    }),
    a2: css({
        backgroundColor: 'white',
        borderRadius: '12px',
        // borderRadius: 6,
        zIndex: 140,
        // boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
        width: '90vw',
        maxWidth: '1040px',
        padding: 25,
        '&:focus': { outline: 'none' },
        [breakpoints.tb]: {
            width: 'calc(100vw - 40px)'
        },
        [breakpoints.mb]: {
            borderRadius: '6px'
        }
    }),
    a3: css({
        position: 'absolute',
        fontFamily: 'inherit',
        height: '43px',
        flex: 'none',
        width: '43px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: '-20px',
        borderRadius: '500px',
        backgroundColor: colors.primaryOrange,
        '&:hover': {
            backgroundColor: colors.primaryOrangeHover
        },
        right: '-20px',
        transition: '0.2s',
        color: colors.shadesWhite,
        boxShadow: '0 0 3px #0003',
        [breakpoints.tb]: {
            width: '36px',
            height: '36px',
            top: '-18px',
            right: '-10px',
            backgroundColor: colors.shadesWhite,
            '&:hover': {
                backgroundColor: colors.neutral1
            },
            color: colors.neutral10
        }
    })
}

export const ModalContent = (
    { className, ...props }: PropsWithChildren<{ className?: string }>,
): JSX.Element => {
    const {
        isOpen,
        setIsOpen
    } = React.useContext(ModalContext)
    return (
        <>
            {isOpen && (
                createPortal(<motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    onClick={() => {
                        setIsOpen(false)
                    }}
                    css={styles.a1}
                >
                    <motion.div
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        initial={{ opacity: 0, y: "20px" }}
                        animate={{ opacity: 1, y: "0px" }}
                        transition={{
                            ease: 'easeInOut'
                        }}
                        css={styles.a2}
                        className={className}
                    >
                        {props.children}
                    </motion.div>
                </motion.div>, document.getElementById("modal-portal"))
            )}
        </>
    )
}

/* this component closes the modal */

export const ModalCloseButton = (
    { className, ...props }: PropsWithChildren<{ className?: string }>
): JSX.Element => {
    const ctx = React.useContext(ModalContext)
    return (
        <button
            className={className}
            css={styles.a3}
            onClick={() => {
                ctx.setIsOpen(false)
            }}
            {...props}
        >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1L1 13M1 1L13 13" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round"/>
            </svg>
        </button>
    );
}
