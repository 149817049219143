import React from 'react';
import clsx from 'clsx';
import Color from '../colors';
import type { ColorProps } from '../colors';
import { PolymorphicWithoutRef } from '../polymorphic';

export const fontSizes = Object.fromEntries([
    'd1',
    'd2',
    'h1',
    'h2',
    'h3',
    'h4',
    's1',
    'p1',
    'p2',
    'c1',
    'c2'
].map((k) => [k,  k]))

const fontWeights = {
    regular: 'regular',
    bold: 'bold',
    medium: 'medium',
    light: 'light'
};

const TypographyContext = React.createContext({ override: false });

export const useTypographyOverride = (): boolean => {
    const ctx = React.useContext(TypographyContext);
    return ctx && ctx.override;
};

const Typography = <C extends React.ElementType = 'div'>({
    override,
    fontWeight,
    fontSize,
    className,
    as,
    ...props
}: PolymorphicWithoutRef<C, {
    override?: boolean,
    fontSize?: keyof typeof fontSizes | { [key: string]: keyof typeof fontSizes },
    fontWeight?: keyof typeof fontWeights
} & ColorProps>): JSX.Element => {
    const value = React.useMemo(() => ({ override: override || false }), [override]);
    return (
        <TypographyContext.Provider value={value}>
            <Color
                as={as as React.ElementType}
                {...props}
                className={clsx(
                    className,
                    // Font size key could be string or number depending on typing of Font Size object
                    (fontSize && (typeof fontSize === 'number' || typeof fontSize === 'string')) && `dt-${fontSize}`,
                    ...(fontSize && typeof fontSize === 'object' ? Object.entries(fontSize)
                        .map(([breakpoint, selection]) => `${breakpoint}-${selection}`) : []),
                    fontWeight && fontWeights[fontWeight],
                    {
                        "html": props.dangerouslySetInnerHTML
                    }
                )}
            />
        </TypographyContext.Provider>
    );
};

export default Typography;
