import {
    AssignRule,
    DefinitionSet,
    Global,
    IdAsArray,
    IdSet,
    LeafAssignRule, LeafSelectRule,
    LeafSizeRule, Option,
    PriceCategory,
    PrimaryPrice,
    Select, Selections,
    Size,
    SizeAddition,
    SizeRule
} from "../../types";
import {toCamelCase} from "./gatsby-data-to-id-set";
import toSentenceCase from "../../../utils/to-sentence-case";
import {toLowerCase} from "js-convert-case";

interface GetPriceForProductProps {
    fieldDefinitions: DefinitionSet,
    ids: IdSet
    idsAsArray: IdAsArray
    selections: Selections
    roomName: string
    parsedPriceDefinitions?: Record<string, unknown>
    isDemoMode?: boolean
}

export interface RichSelection {
    apiKey: string
    title: string
    value: string|number,
    prettyValue: string
    adjusted: boolean,
    apiInterface: 'custom' | 'root' | 'none' | 'order_description'
    hidden?: boolean
    groupCustomizerId: string
}

export interface GetPriceForProductResult {
    price: string
    numericPrice: number
    isEstimated: boolean
    selectionsInfo: Record<string, RichSelection>
    apiMarkup: ApiItemSchemaBeforeOrder[]
    sizeInvalid: boolean
}


export interface ApiItemSchema extends Record<string, string|number|unknown> {
    //width: number
    //drop: number
    //colour: string
    //material: string
    location: string // User's room name
    itemNumber: number
    itemName: string,
    quantity: number
    customFieldValues: { name: string, value: string }[]
}

export interface ApiItemSchemaBeforeOrder extends ApiItemSchema {
    orderDescriptionValues: { name: string, value: string }[]
}

export interface ApiTargetSchema {
    id: number
    orderDescription: string
    purchaseOrderNumber: string
    orderDate: string // ISO String
    locationName: "SHADON",
    address1: string
    address2: string|null,
    city: string,
    state: string,
    postcode: number
    phone: string
    email: string
    contactName: string
    items: ApiItemSchema[]
}

const getPriceForProduct = ({
                                fieldDefinitions,
                                ids,
                                idsAsArray,
                                selections: originalSelections,
                                roomName,
                                parsedPriceDefinitions,
                                isDemoMode,
                            }: GetPriceForProductProps): GetPriceForProductResult => {

    let isIndicative = false;

    const global = idsAsArray.find(x => x.type === "GLOBAL") as Global
    const priceCategories = idsAsArray.filter(x => x.type === "PRICE_CATEGORY") as PriceCategory[]
    const primaryPrices = idsAsArray.filter(x => x.type === "PRIMARY_PRICE") as PrimaryPrice[]

    let baseSurcharges = 0;
    primaryPrices.forEach(({ base_surcharge }) => baseSurcharges += base_surcharge)

    let selections = {...originalSelections}
    let currentApiKeys = Object.keys(selections)

    let {
        item_name,
        item_number
    } = global || {
        item_name: "TESTING",
        item_number: 0
    };

    let basePrice = 0;
    let accumulatedGridPrices = 0;

    const collectedPriceDefinitionIds: string[] = []; // List of Dato Scaling/Stepped Definitions
    const collectedSizeAdditionIds: string[] = []; // List of Size Addition Block IDs
    const collectedSizeRuleIds: string[] = []; // List of Size Rule Block IDs
    const collectedAssignRuleIds: string[] = []; // List of Assign Rule Block IDs

    const additionalProductRulesByIndexKey: { [key: string]: string[] } = {} // arrays of LeafAssign/LeafSize IDs

    const realizedSelections: Record<string, RichSelection> = {}

    let providedSizesAreInvalid = false;

    let currentGroupId = "";

    let sizeWasForked = false;

    const nestAndApply = (parentId: string, forkedProductIndex?: string) => {
        const items = idsAsArray.filter(x => x.parent_customizer_id === parentId).sort((a, b) => a.index - b.index)

        items.forEach((item, idx) => {
            if (item.type === "GROUP") {
                currentGroupId = item.customizer_id;
                nestAndApply(item.customizer_id)
            } else if (item.type === "SELECT") {
                if (isDemoMode && !selections[item.api_key]) {
                    //isIndicative = true
                    const maybeOption = (idsAsArray.filter(x => x.type === "OPTION" && x.parent_customizer_id === item.customizer_id).sort((a, b) => a.index - b.index)?.[0]) as Option
                    selections[item.api_key] = maybeOption?.api_value
                    currentApiKeys.push(item.api_key)
                }

                nestAndApply(item.customizer_id)
            } else if (item.type === "OPTION") {
                const parent = ids[parentId] as Select;
                if (currentApiKeys.includes(parent.api_key) && selections[parent.api_key] === item.api_value) {

                    if (parent.select_definition && fieldDefinitions.selectDefinitions[parent.select_definition]) {
                        if (item.option_definition && fieldDefinitions.optionDefinitions[item.option_definition]) {

                            const selectDef = fieldDefinitions.selectDefinitions[parent.select_definition];
                            const optionDef = fieldDefinitions.optionDefinitions[item.option_definition]

                            if (item.override_item_name) {
                                item_name = item.override_item_name
                            }
                            if (item.override_item_number) {
                                item_number = item.override_item_number
                            }

                            realizedSelections[parent.api_key] = {
                                apiKey: parent.api_key,
                                apiInterface: parent.api_interface,
                                title: selectDef.title,
                                value: item.api_value,
                                prettyValue: optionDef.title,
                                adjusted: false,
                                groupCustomizerId: currentGroupId,
                            }

                            nestAndApply(item.customizer_id)
                        }
                    }

                }
            } else if (item.type === "MEASUREMENT") {
                nestAndApply(item.customizer_id)

            } else if (item.type === "SIZE") {
                // For nested paths, it -has- to be declared by an above option, or is on the primary (un-nested), so this should always be applied


                const value = currentApiKeys.includes(item.api_key) ? selections[item.api_key] as number : item.minimum_value;

                realizedSelections[item.api_key] = {
                    apiKey: item.api_key,
                    apiInterface: item.api_interface,
                    title: item.title,
                    value: value || item.minimum_value, // Solves case where a 'Width' key might be provided as undefined
                    prettyValue: typeof value === "number" ? `${value}mm` : "Not yet set",
                    adjusted: false,
                    groupCustomizerId: currentGroupId,
                }


                if (currentApiKeys.includes(item.api_key)) {
                    if (Number(realizedSelections[item.api_key]) < item.minimum_value || Number(realizedSelections[item.api_key]) > item.maximum_value) {
                        providedSizesAreInvalid = true;
                    }
                }

                nestAndApply(item.customizer_id)
            } else if (item.type === "GLOBAL") {

            } else if (item.type === "PRICE_CATEGORY") {
                // If we got this far, it's because an option sent us here, save the category

                if (item.price_definition) {
                    collectedPriceDefinitionIds.push(item.price_definition)
                }

            } else if (item.type === "PRIMARY_PRICE") {
                // Multiple primary prices are available
                // They will be linked to a select, and then a user will have to select a price category for every option within the select

                const relevantSelect = idsAsArray.find(x => x.customizer_id === item.parent_customizer_id)

                if (relevantSelect) {
                    // They can implement a base surcharge, the base surcharge is always applied (assuming the primary price linked option is global)
                    basePrice += item.base_surcharge || 0

                    // Think? The children of this price (which have the options as parents) should be handled by option recursion
                }

            } else if (item.type === "PRIMARY_VARIANT") {

            } else if (item.type === "SIZE_ADDITION") {
                // Parent is the option, but size might be not existent yet, revisit later
                collectedSizeAdditionIds.push(item.customizer_id)

            } else if (item.type === "SIZE_RULE") {
                // Parent is the option, but size might be not existent yet, revisit later
                collectedSizeRuleIds.push(item.customizer_id)

            } else if (item.type === "ASSIGN_RULE") {
                // Parent is the option, but size might be not existent yet, revisit later
                collectedAssignRuleIds.push(item.customizer_id)

            } else if (item.type === "LEAF_ASSIGN_RULE") {
                // sent here via stem
                if (forkedProductIndex) {
                    additionalProductRulesByIndexKey[forkedProductIndex].push(item.customizer_id)
                }

            } else if (item.type === "LEAF_SIZE_RULE") {
                // sent here via stem
                if (forkedProductIndex) {

                    sizeWasForked = true;
                    additionalProductRulesByIndexKey[forkedProductIndex].push(item.customizer_id)
                }

            } else if (item.type === "LEAF_SELECT_RULE") {
                // sent here via stem
                if (forkedProductIndex) {
                    additionalProductRulesByIndexKey[forkedProductIndex].push(item.customizer_id)
                }

            } else if (item.type === "BRANCH") {
                // sent here via Select recursion

            } else if (item.type === "STEM") {
                // sent here via Option recursion and selection

                const parent = ids[item.parent_customizer_id]

                additionalProductRulesByIndexKey[String(idx)] = []
                nestAndApply(item.customizer_id, String(idx))

            }
        })
    }


    nestAndApply("GROUP")

    // Additions get applied first, then variances, so that variances can be applied to newly created api keys
    collectedSizeAdditionIds.forEach((sizeAdditionId) => {
        const sizeAddition = ids[sizeAdditionId] as SizeAddition

        const sizeA = ids[sizeAddition.size1_customizer_id] as Size
        const sizeAValue = (selections[sizeA.api_key] || sizeA.minimum_value) as number

        const sizeB = ids[sizeAddition.size2_customizer_id] as Size
        const sizeBValue = (selections[sizeB.api_key] || sizeB.minimum_value) as number

        realizedSelections[sizeAddition.summed_value_api_key] = {
            title: sizeAddition.summed_value_title,
            adjusted: true,
            apiInterface: sizeAddition.api_interface,
            value: sizeAValue + sizeBValue,
            prettyValue: `${sizeAValue + sizeBValue}mm`,
            apiKey: sizeAddition.summed_value_api_key,
            groupCustomizerId: (
                realizedSelections[sizeAddition.size1_customizer_id] || realizedSelections[sizeAddition.size2_customizer_id]
            )?.groupCustomizerId
        }
    })

    collectedSizeRuleIds.forEach((sizeRuleId) => {
        const sizeRule = ids[sizeRuleId] as SizeRule

        const applicableSize = ids[sizeRule.size_customizer_id] as Size

        const updatedNumber = realizedSelections[applicableSize.api_key].value as number + sizeRule.variance;

        realizedSelections[applicableSize.api_key] = {
            title: realizedSelections[applicableSize.api_key].title,
            adjusted: true,
            apiInterface: realizedSelections[applicableSize.api_key].apiInterface,
            value: updatedNumber,
            prettyValue: `${realizedSelections[applicableSize.api_key].value}mm`,
            apiKey: realizedSelections[applicableSize.api_key].apiKey,
            groupCustomizerId: (
                realizedSelections[applicableSize.api_key]
            )?.groupCustomizerId
        }
    })

    collectedAssignRuleIds.forEach((assignRuleId) => {
        const assignRule = ids[assignRuleId] as AssignRule

        realizedSelections[assignRule.api_key] = {
            title: assignRule.api_key,
            adjusted: true,
            apiInterface: assignRule.api_interface,
            value: assignRule.api_value,
            prettyValue: assignRule.api_value,
            apiKey: assignRule.api_key,
            hidden: true,
            groupCustomizerId: currentGroupId,
        }
    })
    //console.log("Collected Price Definitions", collectedPriceDefinitionIds, selections, idsAsArray.filter(x => x.type === "PRICE_CATEGORY").map(x => ids[x.parent_customizer_id]))


    const altProductRichSelections: Record<string, Record<string, RichSelection>> = {};
    let hadAltProducts = false;

    Object.entries(additionalProductRulesByIndexKey).map(([key, value]) => {
        hadAltProducts = true;
        altProductRichSelections[key] = {
            ...realizedSelections
        }
        value.forEach((leafId) => {
            const leaf = ids[leafId] as LeafSizeRule | LeafAssignRule | LeafSelectRule

            if (leaf.type === "LEAF_SIZE_RULE") {

                const size = ids[leaf.size_customizer_id] as Size
                const realizedExistingSize = realizedSelections[size.api_key].value as number

                altProductRichSelections[key][toCamelCase(leaf.api_key)] = {
                    adjusted: false,
                    apiInterface: leaf.api_interface,
                    apiKey: leaf.api_key,
                    groupCustomizerId: "",
                    prettyValue: "",
                    title: "",
                    value: realizedExistingSize
                }

            } else if (leaf.type === "LEAF_ASSIGN_RULE") {

                altProductRichSelections[key][toCamelCase(leaf.api_key)] = {
                    adjusted: false,
                    apiInterface: leaf.api_interface,
                    apiKey: leaf.api_key,
                    groupCustomizerId: "",
                    prettyValue: "",
                    title: "",
                    value: leaf.api_value,
                }

            } else if (leaf.type === "LEAF_SELECT_RULE") {

                const select = ids[leaf.select_customizer_id] as Select
                const realizedExistingSelect = realizedSelections[select.api_key]

                if (realizedExistingSelect) {
                    altProductRichSelections[key][toCamelCase(leaf.api_key)] = {
                        adjusted: false,
                        apiInterface: leaf.api_interface,
                        apiKey: leaf.api_key,
                        groupCustomizerId: "",
                        prettyValue: "",
                        title: "",
                        value: realizedExistingSelect.value,
                    }
                }
            }
        })
    })

    const primaryProduct: ApiItemSchemaBeforeOrder = {
        itemName: item_name,
        itemNumber: item_number,
        location: roomName,
        quantity: 1,
        customFieldValues: [],
        orderDescriptionValues: []
    };

    Object.values(realizedSelections).forEach(selection => {
        if (selection.apiInterface === "root") {
            primaryProduct[toCamelCase(selection.apiKey)] = selection.value
        } else if (selection.apiInterface === "custom") {
            primaryProduct.customFieldValues.push({
                name: selection.apiKey,
                value: String(selection.value)
            })
        } else if (selection.apiInterface === "order_description") {
            primaryProduct.orderDescriptionValues.push({
                name: selection.apiKey,
                value: String(selection.value)
            })
        }
    })

    const allApiItems = hadAltProducts ? [] : [
        primaryProduct
    ]

    const allRichSelections = hadAltProducts ? [] : [
        realizedSelections
    ]

    Object.values(altProductRichSelections).forEach((product) => {
        allRichSelections.push(product)
        const newProduct: ApiItemSchemaBeforeOrder = {
            ...primaryProduct
        };
        Object.values(product).forEach(selection => {
            if (selection.apiInterface === "root") {
                newProduct[toCamelCase(selection.apiKey)] = selection.value
            } else if (selection.apiInterface === "custom") {
                newProduct.customFieldValues.push({
                    name: selection.apiKey,
                    value: String(selection.value)
                })
            } else if (selection.apiInterface === "order_description") {
                newProduct.orderDescriptionValues.push({
                    name: selection.apiKey,
                    value: String(selection.value)
                })
            }
        })
        allApiItems.push(newProduct)
    });


    (sizeWasForked ? allRichSelections : [allRichSelections[0]]).forEach((realizedSelections) => {
        collectedPriceDefinitionIds.forEach((priceDefinitionId) => {

            const scalingPriceDefinition = fieldDefinitions.scalingPriceDefinitions[priceDefinitionId]
            const steppedPriceDefinition = fieldDefinitions.steppedPriceDefinitions[priceDefinitionId]

            if (scalingPriceDefinition) {

                const surfaceArea = ((Number(realizedSelections[scalingPriceDefinition.api_key_w].value || 0)) * (Number(realizedSelections[scalingPriceDefinition.api_key_h].value || 0))) / 1000 / 1000
                const sum = (scalingPriceDefinition.price_per_sqm) * surfaceArea

                accumulatedGridPrices += Math.round(sum);

            } else if (steppedPriceDefinition && typeof steppedPriceDefinition.list === "string") {


                const priceData = parsedPriceDefinitions?.[priceDefinitionId] || JSON.parse(steppedPriceDefinition.list)

                // Filter out any prices too low, or non-matching, before sort
                // Price shown should
                const screenedPrices = priceData
                    .filter((x: Record<string, string>) => x.Price || x.price) // Make sure everything has a price field
                    .filter((x: Record<string, string>, idx: number) => {
                        // Do all the prices and requirements match? (every)
                        return Object.entries(x).filter(x => x[0].toLowerCase() !== "price").map(x => [x[0].toLowerCase(), x[1]]).every(([k, v]) => {
                            // key name will be lowercase

                            const altKeyName = (() => {
                                if (k.toLowerCase() === "drop") {
                                    return "height"
                                }
                                if (k.toLowerCase() === "height") {
                                    return "drop"
                                }
                                return ""
                            })()

                            const maybeSelection = realizedSelections[toLowerCase(k)]
                                || realizedSelections[toSentenceCase(k)]
                                || realizedSelections[toLowerCase(altKeyName)]
                                || realizedSelections[toSentenceCase(altKeyName)]

                            if (maybeSelection) {

                                const currentSelectionValue = maybeSelection.value


                                // Handle size integers
                                if (typeof currentSelectionValue === "number" && String(Number(v)) === v) {
                                    const spreadsheetMinimumNumber = Number(v)
                                    const currentSelectionSizeNumber = currentSelectionValue as number

                                    if (spreadsheetMinimumNumber >= currentSelectionSizeNumber) {
                                        return true
                                    }
                                }
                                // Handle selects
                                if (typeof currentSelectionValue === "string") {
                                    return currentSelectionValue === v
                                }

                            }
                            return false
                        })
                    })
                    .map((x) => {
                        return Number(x.Price || x.price)
                    })
                    .sort((a, b) => {
                        return a - b
                    })

                if (screenedPrices[0]) {
                    accumulatedGridPrices += screenedPrices[0]
                } else {
                    const lowestPossiblePrice = priceData
                        .map((x) => {
                            return Number(x.Price || x.price)
                        })
                        .sort((a, b) => {
                            return a - b
                        })
                    if (lowestPossiblePrice[0]) {
                        accumulatedGridPrices += lowestPossiblePrice[0]
                        isIndicative = true
                    }
                }
            }

        })
    })

    const finalPrice = basePrice + accumulatedGridPrices;
    const formattedPrice = finalPrice.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD'
    })

    return {
        price: formattedPrice.replace(".00", ""),
        numericPrice: finalPrice,
        apiMarkup: allApiItems,
        isEstimated: isIndicative,
        selectionsInfo: realizedSelections,
        sizeInvalid: providedSizesAreInvalid,
    }
}

export default getPriceForProduct;
